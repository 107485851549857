import { createAction, props } from '@ngrx/store';

import { IstatGroup } from '../../models';

export const loadDetailsRequest = createAction(
  '[Istat Groups] Load Details Request',
  props<{ id: number }>(),
);

export const loadDetailsSuccess = createAction(
  '[Istat Groups] Load Details Success',
  props<{ data: IstatGroup }>(),
);

export const loadDetailsFailure = createAction(
  '[Istat Groups] Load Details Failure',
  props<{ error: any }>(),
);

export const loadRequest = createAction(
  '[Istat Groups] Load Request',
  props<{ propertyIds: number[] }>(),
);

export const loadSuccess = createAction(
  '[Istat Groups] Load Success',
  props<{ items: IstatGroup[] }>(),
);

export const loadFailure = createAction(
  '[Istat Groups] Load Failure',
  props<{ error: any }>(),
);

export const deleteRequest = createAction(
  '[Istat Groups] Delete Request',
  props<{ id: number }>(),
);

export const deleteSuccess = createAction(
  '[Istat Groups] Delete Success',
  props<{ id: number }>(),
);

export const deleteFailure = createAction(
  '[Istat Groups] Delete Failure',
  props<{ error: any }>(),
);

export const createRequest = createAction(
  '[Istat Groups] Create Request',
  props<{ data: IstatGroup; agencyCustoms: Record<string, any> }>(),
);

export const createSuccess = createAction(
  '[Istat Groups] Create Success',
  props<{ data: IstatGroup }>(),
);

export const createFailure = createAction(
  '[Istat Groups] Create Failure',
  props<{ error: any }>(),
);

export const updateRequest = createAction(
  '[Istat Groups] Update Request',
  props<{ data: IstatGroup; agencyCustoms: Record<string, any> }>(),
);

export const updateSuccess = createAction(
  '[Istat Groups] Update Success',
  props<{ data: Partial<IstatGroup> }>(),
);

export const updateFailure = createAction(
  '[Istat Groups] Update Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Istat Groups] Reset State');
