import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { IstatGroup } from '../../models';

export const featureAdapter: EntityAdapter<Partial<IstatGroup>> =
  createEntityAdapter<IstatGroup>({
    selectId: (model) => model.id,
  });

export interface State extends EntityState<Partial<IstatGroup>> {
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
});
