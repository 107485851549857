import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { IstatGroup } from '../../models';
import { IstatGroupsService } from '../../services';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class IstatGroupsStoreEffects {
  constructor(
    private dataService: IstatGroupsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private router: Router,
    private notifications: NotificationService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ propertyIds }) =>
        this.dataService.load(propertyIds).pipe(
          map((response) =>
            fromActions.loadSuccess({
              items: response.data,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createRequest),
      switchMap(({ data, agencyCustoms }) =>
        this.dataService.createAndSetCustomsFields(data, agencyCustoms).pipe(
          map((response: IResponseSuccess<IstatGroup[]>) => {
            return fromActions.createSuccess({
              data: response.data[0],
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.createFailure(error));
          }),
        ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateRequest),
      switchMap(({ data, agencyCustoms }) =>
        this.dataService.updateAndSetCustomsFields(data, agencyCustoms).pipe(
          switchMap((response) => {
            if (!this.errorHandler.handleWarnings(response)) {
              this.notifications.updateSuccess('group');
              this.router.navigate(['configuration', 'istat-groups']);
            }
            return [
              fromActions.updateSuccess({
                data: response.data[0],
              }),
            ];
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.updateFailure(error));
          }),
        ),
      ),
    ),
  );

  loadDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadDetailsRequest),
      switchMap(({ id }) =>
        this.dataService.loadDetails(id).pipe(
          map((response) =>
            fromActions.loadDetailsSuccess({
              data: response.data[0],
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadDetailsFailure(error));
          }),
        ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteRequest),
      switchMap(({ id }) =>
        this.dataService.delete(id).pipe(
          map(() => {
            this.notifications.deleteSuccess('group');
            return fromActions.deleteSuccess({
              id,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.deleteFailure(error));
          }),
        ),
      ),
    ),
  );
}
